/* General Styling */
.services_main {
    padding: 50px 10%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
}

.services_list {
    display: flex;
    width: 50%;
    flex-flow: row wrap;
    justify-content: space-around;
}

.serviceContainer {
    width: 300px;
    height: 250px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 4px;
    font-size: .9em;
    margin: 10px;
}

.serviceIllustrationContainer {
    display: none;
    align-items: center;
    margin: 10px 0;
}

.serviceIllustrationContainer > img {
    width: 50px;
}

.serviceTitle {
    font-weight: 600;
    color: #000000;
    padding: 10px 0;
}

.serviceMain {
    font-size: 1em;
}

.servicesTitle {
    font-size: 3.5em;
    color: #2c2d43;
    padding: 10px 0;
    font-weight: 500;
    margin-bottom: 50px;
}

#servicesTitleBar {
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.servicesTitleBarContent {
    font-size: .9em;
    padding: 10px;
    width: 100%;
    margin-right: 40px;
}

.footerEmailInput {
    padding: 10px;
    margin: 5px 0;
}

.footerEmail {
    margin: 10px 0;
}

.flex_row {
    display: flex;
    align-items: center;
}

.btn {
    padding: 10px;
    background-color: #171722;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0;
    cursor: pointer;
}

.services_expounded {
    display: flex;
    flex-flow: row wrap;
}

/* Responsive Design */
@media screen and (max-width: 1200px) {
    #servicesTitleBar {
        width: 100%;
    }

    .services_list {
        width: 100%;
        flex-direction: column;
    }

    .serviceContainer {
        width: 100%;
        height: auto;
        margin: 10px 0;
    }

    .serviceTitle {
        font-size: 1.2em;
    }

    .serviceMain {
        font-size: 1.1em;
    }
}

@media screen and (max-width: 768px) {
    .services_main {
        padding: 50px 5%;
        flex-direction: column;
        align-items: center;
    }

    .services_list {
        width: 100%;
        flex-direction: column;
        justify-content: center;
    }

    .serviceContainer {
        width: 100%;
        margin-bottom: 20px;
    }

    .serviceTitle {
        font-size: 1.5em;
        padding: 10px 0;
    }

    .serviceMain {
        font-size: 1.2em;
    }

    #servicesTitleBar {
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
    }

    .servicesTitle {
        font-size: 2.5em;
    }

    .servicesTitleBarContent {
        font-size: 1em;
        padding: 15px 0;
    }
}
