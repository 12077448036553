.splash{
    
    position: relative;
    min-height: 700px;
    top: 100px;
}
#background{
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#foreGround{
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 5000;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    height: 100%;
    padding: 0 10%;
    color: white;
    background-color: rgba(53, 53, 53, 0.574);
}

.splashTitle{
    font-size: 2em;
    font-weight: 600;
 
}
.splash_sub{
    padding-top: 20px;
    color: inherit;
}
.splash_btn{
    display: inline-block;
    padding: 10px;
    margin: 30px 0px;
    background-color: #0f1012;
    color: white;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
    
}
.splashVideo{

    width: 100%;


}
.splashInfoSection{
   
    color: inherit;
}
.splashSectionHeading{
    font-size: 2.2em;
    font-weight: 500;
    color: inherit;
    
}
.getStartedFeatures{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    color: inherit;
}
.getStartedFeature{
    display: flex;
    flex-direction: row;
    width: 200px;
    color: inherit;

}
.getStartedFeature > div{
    color: inherit;
}
.getStartedFeature > div:first-child{
    height: 50px;
    width: 50px;
    margin:0 10px;
}
.splashSectionMain{
    width: 300px;
    color: inherit;
}
@media screen and (max-width:1200px){
    .splashVideo{
       
  
       display: none;
    
    }
    #foreGround{
        background-color: rgb(255,255,255);
        color: inherit
    }
   
}
@media screen and (max-width:900px){
    .splash{
        height: auto;
        padding: 150px 0;
    }
    .splash_sub{
       
        width: 100%;
    
    }

}
@media screen and (max-width:600px){

}