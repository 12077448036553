.clients {
    width: 100%;
    min-height: 800px;
    padding: 50px 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.clientHead {
    width: 50%;
    text-align: center;
    margin-bottom: 40px;
}

.headDetails {
    padding: 20px 0;
    font-size: 1.1em;
    color: #555;
}

.clientHeadTitle {
    font-size: 4em;
    font-weight: 600;
    margin-bottom: 20px;
}

.clientsList {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    gap: 20px; /* Ensures proper spacing between client items */
}

.client {
    width: 250px;
    height: 200px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: capitalize;
    font-size: .8em;
    font-weight: 600;
    text-align: center;
}

.client img {
    height: 50px;
    margin: 20px 0px 40px;
    opacity: .7;
}

.freetown {
    text-transform: uppercase;
    width: 300px;
    text-align: center;
}

/* Large screen responsiveness */
@media screen and (max-width: 1200px) {
    .clientHead {
        width: 50%;
    }

    .client {
        width: 220px; /* Reduce size for medium screens */
    }
}

/* Medium screen responsiveness */
@media screen and (max-width: 900px) {
    .clientHead {
        width: 80%;
    }

    .client {
        width: 200px; /* Further reduce size for smaller screens */
        height: 180px;
    }

    .clientHeadTitle {
        font-size: 3em;
    }

    .headDetails {
        font-size: 1em;
    }
}

/* Mobile screen responsiveness */
@media screen and (max-width: 600px) {
    .clientHead {
        width: 100%;
    }

    .client {
        width: 100%;
        margin: 10px 0;
        height: auto;
    }

    .client img {
    /* Reduce image size */
        margin: 15px 0;
    }

    .clientHeadTitle {
        font-size: 2.5em;
    }

    .headDetails {
        font-size: .9em;
        padding: 15px 0;
    }
}
