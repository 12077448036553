:root {
    --footer-bg: #141414;
    --footer-accent: #80e5d8;
    --footer-text: #ffffff;
    --footer-heading: #f0f0f0;
    --footer-font-small: 0.9rem;
    --footer-font-medium: 1rem;
    --footer-font-large: 1.2rem;
}

.footer {
    background-color: var(--footer-bg);
    color: var(--footer-text);
    padding: 40px 5%;
    text-align: center;
    color: white;
}

.footerHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;
}

.footerLogo {
    height: 60px;
}

.footerBrand {
    text-align: left;
}

.footerCompanyName {
    font-size: var(--footer-font-large);
    font-weight: bold;
    color: var(--footer-accent);
    margin: 0;
}

.footerTagline {
    font-size: var(--footer-font-small);
    margin: 5px 0 0;
}

.footerContent {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
    margin-bottom: 30px;
}

.footerSection {
    max-width: 300px;
    text-align: left;
}

.footerSectionTitle {
    font-size: var(--footer-font-medium);
    font-weight: bold;
    color: var(--footer-heading);
    margin-bottom: 10px;
}

.footerList {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: var(--footer-font-small);
    line-height: 1.6;
    color: white
}
.footerList li, .footerList strong{
    color: inherit
}

.footerForm {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.footerInput {
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: var(--footer-font-small);
}

.footerButton {
    padding: 10px;
    background-color: var(--footer-accent);
    color: var(--footer-bg);
    border: none;
    border-radius: 4px;
    font-size: var(--footer-font-medium);
    cursor: pointer;
    transition: background-color 0.3s;
}

.footerButton:hover {
    background-color: #60cab8;
}

.socialIcons {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.socialIcon {
    width: 40px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    transition: opacity 0.3s;
}

.socialIcon:hover {
    opacity: 0.7;
}



.footerBottom {
    border-top: 1px solid #333;
    padding-top: 20px;
    font-size: var(--footer-font-small);
    color: inherit;
}
.footerBottom p{
    color: white;
}