/* General Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  background-color: #f9f9f9;
  color: #333;
}

/* Container */
.servicesContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin: 0 auto;
  text-align: center;
  margin-top: 150px;
}

/* Header */
.header {
  margin-bottom: 30px;
}

.title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #222;
}

.subtitle {
  font-size: 1rem;
  color: #555;
  margin-bottom: 20px;
}

/* Content */
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.mainImage {
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.description {
  font-size: 1rem;
  color: #444;
  margin-bottom: 20px;
}

.ctaButton {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007067;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.ctaButton:hover {
  background-color: #00564d;
}

/* Responsive Design */
@media (max-width: 768px) {
  .title {
    font-size: 1.8rem;
  }

  .subtitle {
    font-size: 0.9rem;
  }

  .description {
    font-size: 0.9rem;
  }

  .ctaButton {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .mainImage {
    max-width: 100%;
  }

  .title {
    font-size: 1.5rem;
  }

  .subtitle {
    font-size: 0.8rem;
  }

  .description {
    font-size: 0.8rem;
  }

  .ctaButton {
    font-size: 0.8rem;
    padding: 6px 12px;
  }
}
