.point_main{
    display: flex;
    flex-flow: row wrap;
    padding: 100px 0;
    justify-content: space-between;
}


#whyusHead{
    font-size: 2.3em;
    color: #424363;
    font-weight: 400;
    padding: 10px 0 20px;
}
#why_us_start{
    text-transform: uppercase;
    color: #0000ff;
}
#point{
    width: 40%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;

}  
#point > div:nth-child(2){
    font-size: .9em;

}  
.mlKeys{
    display: flex;
    flex-flow: row wrap;
    padding-top: 40px;
    align-items: flex-start;
    justify-content: space-between;
}   
.mlPoint {
    display: flex;
    width: 180px;
    align-items: center;
    margin: 10px;
}
.mlPoint > div:first-child{
    width: 50px;
    margin: 0 20px;
    display: none;
}
.mlContent{
    display: flex;
    flex-flow: row wrap;
    width: 70%;
}
.mlSection{
    display: flex;
    padding: 0 10%;

}
.mlKeysContainer{
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;

}
.mlIllustration{

    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.mlIllustration > img{


    height: 100%;

}
.mlIllustrationContainer{

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 40%;
}
.mTitle{
    font-size: 6em;
    font-weight: 300;
    width: 70%;
    line-height: 1em;
}
@media screen and (max-width: 1200px){
    .whyus_illustration{
        width: 100%;
    }
    #point{
        width: 100%;
    
    }
}