/* Navbar Base Styling */
.Nav {
    display: flex;
    height: 100px;
    width: 100%;
    justify-content: space-between;
    font-weight: 400;
    padding: 0 5%;  /* Reduced padding for better responsiveness */
    position: fixed;
    top: 0;
    left: 0;
    z-index: 7000;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);  /* Added subtle shadow for separation */
}

/* Logo and Brand Name Styling */
.logoname {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}

.logoname > div:first-child {
    font-weight: 600;
    text-transform: capitalize;
    font-size: 1.1em;  /* Increased font size for clarity */
    color: #2c3e50;    /* Dark text for visibility */
}

.logoname > div:last-child {
    font-size: 0.8em;
    color: #7f8c8d;  /* Lighter color for subtext */
}

/* Flex Row Styling for Navbar Items */
.flexRow {
    display: flex;
    align-items: center;
}

/* Navigation Link Styling */
.NavLink {
    padding: 15px;
    font-size: 1em;  /* Increased font size for better readability */
    text-transform: uppercase;
    font-weight: 500;
}

.NavLink .nLink {
    text-decoration: none;
    color: #2c3e50;
    cursor: pointer;
    padding: 5px;
}

.NavLink .nLink:hover {
    color: #3498db;  /* Added hover effect for better UX */
    border-bottom: 2px solid #3498db; /* Underline on hover */
}

/* Logo Styling */
.logo {
    height: 40px; /* Increased logo size for better visibility */
    margin-right: 10px;
}

/* Drawer Icon Styling */
.drawerController {
    display: none;
}

@media screen and (max-width: 768px) {
    /* On smaller screens, adjust layout */
    .Nav {
        padding: 0 20px;  /* Reduced padding for small screens */
        justify-content: space-between;
    }

    .logoname {
        display: none;  /* Hide logo name on small screens */
    }

    /* Show Drawer Controller on mobile */
    .drawerController {
        display: block;
    }

    /* Align menu icon to the right */
    .drawerController .MuiIconButton-root {
        color: #2c3e50;
        padding: 10px;
    }

    /* Increase logo size for better mobile visibility */
    .logo {
        height: 35px;
    }

    /* Hide NavLink items on mobile */
    .NavLink {
        display: none;
    }
}

/* Drawer Styling */
.homeDrawer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #ecf0f1;
}

.drawerItem {
    font-size: 1.1em;
    padding: 10px 20px;
    width: 200px;
    cursor: pointer;
}
.drawer{
    z-index: 10000
}
.drawerItem:hover {
    background-color: #3498db; /* Added hover effect in drawer */
    color: white;  /* Change text color when hovered */
}

/* Disabled Links Styling */
.disabled {
    color: rgba(50, 50, 50, 0.4);
}

/* Drawer content styling for mobile */
.drawerController .MuiIconButton-root {
    color: white;
}

/* Preventing text overflow in menu items */
.drawerItem {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
