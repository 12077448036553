/* General Layout */
.processMain {
    padding: 8% 10%;
    background: #f9f9f9;
    position: relative;
    overflow: hidden;
  }
  
  /* Abstract Background Shapes */
  .processMain::before,
  .processMain::after {
    content: '';
    position: absolute;
    border-radius: 50%;
    z-index: -1;
  }
  
  .processMain::before {
    width: 400px;
    height: 400px;
    background: rgba(0, 123, 255, 0.1);
    top: 10%;
    left: -50px;
  }
  
  .processMain::after {
    width: 300px;
    height: 300px;
    background: rgba(0, 255, 123, 0.1);
    bottom: 5%;
    right: -50px;
  }
  
  /* Title Section */
  #processTitle {
    padding: 0 0 80px;
    text-align: left;
  }
  
  #processIntro {
    font-size: 3.5em;
    font-weight: bold;
    line-height: 1.2;
    color: #1a1a1a;
  }
  
  #processIntro > div:nth-child(1) {
    color: #333;
  }
  
  #processIntro > div:nth-child(2) {
    padding: 10px;
    display: inline-block;
    color: #0078f6;
    border-bottom: 25px solid rgba(0, 120, 246, 0.1);
    line-height: 0;
  }
  
  #processTitle > div:nth-child(2) {
    font-size: 1.2em;
    color: #666;
    max-width: 800px;
    margin-top: 20px;
  }
  
  /* Bottom Main Section */
  #processBottomMain {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
  }
  
  /* Illustration */
  #processIllustration {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #processIllustration > img {
    width: 80%;
    transition: transform 0.3s ease-in-out;
  }
  
  #processIllustration > img:hover {
    transform: scale(1.05);
  }
  
  /* Stage Component */
  .processStage,
  .processStageActive {
    width: 400px;
    padding: 20px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .processStage {
    border-bottom: 1px solid #ccc;
  }
  
  .processStage:hover {
    background: rgba(0, 123, 255, 0.1);
  }
  .processStage div{
    pointer-events: none;
  }
  
  .processStageActive {
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    margin: 20px 0;
    background: rgba(0, 123, 255, 0.1);
  }
  
  /* Icon Styles */
  .stageIcon,
  .stageIconActive {
    margin-right: 20px;
  }
  
  .stageIcon img {
    width: 30px;
  }
  
  .stageIconActive img {
    width: 30px;
  }
  
  .stageIconActive {
    background: #007bff;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive Adjustments */
  @media screen and (max-width: 900px) {
    #processIllustration {
      width: 100%;
      margin-bottom: 40px;
    }
  
    #processIntro {
      font-size: 2.5em;
    }
  
    .processStage,
    .processStageActive {
      width: 100%;
    }
  }
  
  @media screen and (max-width: 600px) {
    #processIntro {
      font-size: 2em;
    }
  }
  


