@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: 'Syne';
    src: URL('../../resources/fonts/Syne/Syne-VariableFont_wght.ttf') format('truetype');
}

*{
    font-smooth: always;
    color: #0F1012;
}

body{

    font-family: Syne, sans-serif;
}
.centered_container{
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
}
.centered_container_wide{
    width: 100%;
    padding-left: 20%;
    padding-right: 20%;
}
.flex_container{
    display: flex;
    align-items: center;

}
.padh10{
    padding-left: 10px;
    padding-right: 10px;
}
.padv20{
    padding-top: 20px;
    padding-bottom: 20px;
}
.boldText{
    font-weight: 500;
}
.heading{
    font-weight: 600;
    color:#424363;
}
.illustration{
    width: 70%;
    background-color: red;
    margin: 20px;
}



.contactsMain{
    display: flex;
    flex-flow: row wrap;
    padding: 10%;
}
.contactsList{
   width: 40%;
}
.contactsForm{
    flex: 1;
    border-radius: 10px;
    padding: 0 5%;
}
.contactInput{
    width: 100%;

}
.contactHeadForm{

}
.contactInputContainer{
    margin: 40px 0;
}
.contactHeadTitle{
    font-size: 2em;
    padding: 10px 0;
}
.getStarted{
    margin-top: 100px;
}
.contactType{
    padding: 20px;
}
.contactTypeTitle{
    font-size: 1.2em;
    font-weight: 700;
    padding-bottom: 5px;
}
.contact_{
    padding-top: 10px;
    font-weight: 600;
}
@media screen and (max-width:1200px){
   
}
@media screen and (max-width:900px){
    .centered_container{
       width: 80%;
       margin-left: 10%;
       margin-right: 10%;
    }
}
@media screen and (max-width:600px){
    .centered_container{
        width: 80%;
        margin-left: 10%;
        margin-right: 10%;
    }
}