.point_main{
    display: flex;
    flex-flow: row wrap;
    padding: 100px 0;
    justify-content: space-between;
}

.InlineHead{
    width: 30%;
    color: white;
}
.projectHead{
    
    font-weight: 300;
    color: inherit;

  
 
}
.projectHeadTitle{
    width: 30%;
    font-size: 2em;
}
.projectHeadTitle2{
    flex:1;
    font-size: 4em;
    color: inherit;
}
.projectNumber{
    font-size: 10em;
    line-height: .8em;
    padding-right: 5%;
    color: inherit;
}

#why_us_start{
    text-transform: uppercase;
    color: #0000ff;
}
.project{
    margin: 0 10%;
    padding: 5% 0;
    color: inherit;
}   
.underlined-project{
    border-bottom: 5px solid;
    border-color: inherit;
}
#point > div:nth-child(2){
    font-size: .9em;

}  
.projectIntro{
    width: 50%;
    color: white;
}
.projectKeys{
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-around;
    padding: 50px 0;
    width: 100%;
}
.projectKeysInlineHead{
    display: flex;
    flex-flow: row wrap;
    align-items: flex-start;
    justify-content: space-between;
    padding: 50px 0;
    width: 100%;
    color: inherit;
}
.projectKey{
    width: 200px;
    height: 300px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    color: inherit;
   
}
.projectKey > div:nth-child(2){
    color: inherit;
   
}
.projectKeyTitle{
    font-weight: 600;
    padding-bottom: 20px;
    height: 100px;
    color: white;
}
.projectKeysContainer{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    color: inherit;
}
.services_page{
    background-color: #005b85;
    color: rgb(193, 193, 193);
}
@media screen and (max-width: 1200px){
    .whyus_illustration{
        width: 100%;
    }
    #point{
        width: 100%;
    
    }
    .InlineHead{
        width: 100%;
    }
    .projectIntro{
        width: 70%;
    }
}
@media screen and (max-width: 900px){
    .projectHeadTitle2{
        font-size: 4em;
        
    }
    .projectNumber{
        font-size: 7em;
        line-height: .8em;
    }
    .projectIntro{
        width: 80%;
    }
    
}

@media screen and (max-width: 600px){
    .projectHeadTitle2{
        font-size: 3em;
    }
    .projectNumber{
        font-size: 5em;
        line-height: .8em;
    }
    .projectIntro{
        width: 100%;
    }
}

@media screen and (max-width: 400px){
    .projectHeadTitle2{
        font-size: 3em;
    }
    .projectNumber{
        font-size: 4em;
        line-height: .8em;
    }
}