.whyus_main {
    display: flex;
    flex-direction: column;
    padding: 100px 10%;
    justify-content: center;
    align-items: center;
    position: relative;
}

#whyUsHead {
    font-size: 4em;
    font-weight: 500;
    padding: 10px 0 30px;
    width: 60%;
    text-align: center; /* Center text on smaller screens */
}
#whyUsMainContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}
#whyUsHeadSub {
    width: 50%;
    font-size: 1.1em;
    text-align: center; /* Center the paragraph */
    margin-bottom: 50px;
}

.why_us_main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

}

.why_us_point_container {
    display: flex;
    width: 100%;
    padding: 0 20px;
    flex-wrap: wrap;
    justify-content: space-between;
}

.why_us_point {
    padding: 20px;
    width: 250px;
    margin-bottom: 30px; /* Add margin between points */
    text-align: center; /* Center the content inside each point */
}

.why_us_point > span:nth-child(2) {
    font-size: .9em;
    margin-top: 10px;
}

.why_us_point_heading {
    font-weight: 600;
    font-size: 1.2em;
    margin-bottom: 10px;
}

/* For illustration image */
.whyus_illustration > img {
    width: 100%;
}

/* Block styling */
.whyus_block {
    width: 50%;
    height: 70%;
    background-color: rgba(70, 200, 255, 0.104);
    position: absolute;
    right: 0;
    bottom: 5%;
    z-index: 1;
}

/* Mobile Responsiveness */
@media screen and (max-width: 768px) {
    #whyUsHead {
        font-size: 2.5em; /* Reduce size for smaller screens */
        width: 90%; /* Ensure the title doesn't stretch too wide */
    }

    #whyUsHeadSub {
        width: 80%;
        font-size: 1em;
    }

    .why_us_point_container {
        width: 100%; /* Take full width */
        justify-content: center; /* Center the items on small screens */
    }

    .why_us_point {
        width: 100%; /* Take full width on small screens */
        margin-bottom: 20px;
    }

    .whyus_block {
        width: 90%;
        height: 50%;
        bottom: 20%;
    }
}

@media screen and (max-width: 480px) {
    #whyUsHead {
        font-size: 2em; /* Make the title even smaller */
        width: 100%; /* Take full width */
    }

    #whyUsHeadSub {
        width: 100%;
        font-size: .9em;
    }

    .why_us_point {
        width: 100%; /* Full width for points */
    }

    .whyus_block {
        width: 100%;
        height: 40%;
    }
}
